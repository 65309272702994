/* General Styles */
:root {
  --font-primary: 'Montserrat', 'Lora', Arial, sans-serif;
  --font-secondary: 'Lora', serif;
}
.App {
  font-family: 'Montserrat', 'Lora', Arial, sans-serif;
  margin: 0;
  padding: 0;
}

/* Header Styles */
.App-header {
  background-color: white;
  color: #333;
  padding: 1rem;
  text-align: center;
  font-family: 'Lora', serif;
}

/* Logo Styles */
.logo {
  width: 25%; /* Adjust the size as needed */
}

/* Navigation Styles */
nav ul {
  list-style-type: none;
  padding: 0;
  text-align: center;
}

nav ul li {
  display: inline;
  margin: 0 1rem;
}

nav ul li a {
  color: #333;
  text-decoration: none;
  font-family: 'Montserrat',
}

/* Main Content Styles */
main {
  background-color: #f0f0f0;
  min-height: 80vh; /* Ensure the main section always takes full viewport height */
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: 'Montserrat',
}

/* Additional Styling for Flexbox */
.flex-grow-1 {
  flex-grow: 1; /* Ensure content stretches to fill the remaining space */
}

/* Gallery Styles */
.gallery-contact {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  height: 100vh;
  background-size: cover;
  background-position: center;
}

.Service-Wrapper{
  /* display: flex; */
  /* flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  height: 100vh; */
  background-size: cover;
  background-position: center;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1rem;
  height: 200vh;
}

.gallery div {
  width: 100%;
  /* height: 100%; */
}

.gallery img {
  width: 100%;
  /* height: 90%; */
  object-fit: cover;
  animation: fadeIn 2s ease-in-out;
}

/* Footer Styles */
footer {
  background-color: #333;
  color: white;
  text-align: center;
  /* position: fixed; */
  width: 100%;
  bottom: 0;
  max-width: 100vw;
  padding: 0px;
  height: 120px;
}

/* Flexbox Utility Classes */
.d-flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

/* Media Query for Larger Screens */
@media (min-width: 768px) {
  .App-header {
    text-align: center;
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    margin-bottom: 0;
  }

  nav ul {
    text-align: center;
  }
  .gallery img {
    width: 50%;
    height: 100%;
  }
  .gallery div {
    width: 50%;
    height: 100%;
  }

  .gallery {
    height: 100vh;
  }
}


/* Service Section Styles */
.services-container {
  padding: 20px;
  font-family: 'Montserrat', 
}

.service-section {
  margin-bottom: 20px;
}

.service-section h2 {
  color: #333;
  font-family: 'Montserrat',
}

.service-section ul {
  list-style-type: none;
  padding: 0;
}

.service-section li {
  margin: 5px 0;
}

/* Contact Section Styles */
.contact-container {
  padding: 40px;
  max-width: 800px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Montserrat', 
}

.contact-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 40px;
  font-size: 36px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'Lora', serif;
}

.contact-section {
  margin-bottom: 30px;
}

.contact-section h2 {
  color: #444;
  font-size: 24px;
  margin-bottom: 10px;
  border-bottom: 2px solid #e67e22;
  display: inline-block;
  padding-bottom: 5px;
  font-family: 'Lora', serif;
}

.contact-section p, .contact-section ul {
  font-size: 18px;
  color: #333;
  line-height: 1.6;
}

.contact-section ul {
  list-style-type: none;
  padding: 0;
}

.contact-section li {
  margin: 5px 0;
}

.social-icons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.social-icons a {
  margin: 0 15px;
  color: #333;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #e67e22;
}

iframe {
  width: 100%;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: slideIn 1s ease-in-out;
}

@media (max-width: 600px) {
  .contact-container {
    padding: 20px;
  }

  .contact-container h1 {
    font-size: 28px;
  }

  .contact-section h2 {
    font-size: 20px;
  }

  .contact-section p, .contact-section ul {
    font-size: 16px;
  }

  .social-icons a {
    margin: 0 10px;
  }
}

/* About Section Styles */
.about-container {
  padding: 20px;
  font-family: 'Montserrat', 
}

.about-container h1 {
  color: #333;
  font-family: 'Lora', serif;
}

.about-container p {
  color: #555;
  line-height: 1.6;
}

.services-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #ffffff9d;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  font-family: 'Montserrat', 
}
.services-container h1 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: #444;
  font-family: 'Lora', serif;
  animation: slideIn 1s ease-in-out;
}
.service-section {
  margin-bottom: 40px;
  animation: slideIn 1s ease-in-out;
}
.service-section h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #555;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
  font-family: 'Lora', serif;
}
.service-section ul {
  list-style: none;
  padding: 0;
}
.service-section ul li {
  font-size: 1.2em;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
}
.service-section ul li:last-child {
  border-bottom: none;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideIn {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.fadeIn {
  animation: fadeIn 2s ease-in-out;
}

.slideIn {
  animation: slideIn 1s ease-in-out;
}

/* Added styles for intersection observer */
.animate-on-scroll {
  opacity: 0;
  transition: opacity 1s, transform 1s;
}

.in-view {
  opacity: 1;
  transform: none;
}
